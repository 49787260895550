import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import api from '../../api/apiClient';
import { loginRequest, loginSuccess, loginError, acknowledgeLoginError, showMaintenanceModal } from '../../actions';
import { saveState } from '../../services/localStorage';
import {
  APPLICATION_BASE_PATH,
  API_ERROR_CODES,
  DATE_FORMAT,
  MAINTENANCE_STORAGE_KEY,
  AUTHORITY
} from '../../constants';
import metadata from '../../metadata.json';
import { ReactComponent as Logo } from '../../assets/clinic-logo.svg';
import Button from '../Button';
import Spinner from '../Spinner';
import Dialog from '../Journal/Dialog';
import './LoginView.scss';

const allowCredentialsLogin =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_CREDENTIALS_LOGIN === 'enabled';

const POLL_RATE_IN_MS = 1000;

const getTimeUntilNextRequest = (requestTime) => {
  return POLL_RATE_IN_MS - requestTime > 0 ? POLL_RATE_IN_MS - requestTime : 0;
};

const LoginView = (props) => {
  const [loginState, _setLoginState] = useState('idle');
  const loginStateRef = useRef('idle');
  const [credentialLoginModalVisible, setCredentialLoginModalVisible] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [activeQrCode, setActiveQrCode] = useState('');
  const timeoutRef = useRef();

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    if (props.loginError) {
      setLoginState('error');
    }
  }, [props.loginError]);

  const checkMaintenanceMode = (loginResponse) => {
    if (loginResponse.secondsUntilMaintenanceMode) {
      props.showMaintenanceModal();
      const maintenanceStartsAt = moment().add(loginResponse.secondsUntilMaintenanceMode, 's');
      saveState(maintenanceStartsAt.format(DATE_FORMAT), MAINTENANCE_STORAGE_KEY);
    }
  };

  const beginLoginFlow = async () => {
    setLoginState('starting');
    try {
      const start = new Date();
      const response = await api.beginLoginFlow();
      const finish = new Date();
      setActiveQrCode(window.atob(response.body.QRCode));
      setLoginState('polling');
      setTimeout(() => {
        refreshQrCode(response.body.sessionId);
      }, getTimeUntilNextRequest(finish - start));
    } catch (err) {
      setLoginState('error');
      props.onLoginError(err);
    }
  };

  const refreshQrCode = async (sessionId) => {
    if (!sessionId) {
      return;
    }

    try {
      const start = new Date();
      const response = await api.refreshQrCode(sessionId);
      const finish = new Date();
      const status = response.body.sessionResponse?.grandidObject?.message?.status;
      const hintCode = response.body.sessionResponse?.grandidObject?.message?.hintCode;

      if (hintCode === 'userSign') {
        setLoginState('user_signing');
      }

      if (status === 'failed') {
        if (hintCode === 'userCancel') {
          setLoginState('idle');
        } else {
          props.onLoginError(I18n.t('login_view.login_failed'));
          setLoginState('error');
        }
        return;
      }

      if (status === 'succeeded' && response.body.loginResponse) {
        setLoginState('authenticated');
        checkMaintenanceMode(response.body.loginResponse);
        props.onLoginSuccess(response.body.loginResponse);
        return;
      }

      setActiveQrCode(window.atob(response.body.sessionResponse.grandidObject.QRCode));

      if (['polling', 'user_signing'].includes(loginStateRef.current)) {
        timeoutRef.current = setTimeout(() => {
          refreshQrCode(sessionId);
        }, getTimeUntilNextRequest(finish - start));
      }
    } catch (err) {
      console.log(err);
      setLoginState('error');
      props.onLoginError(err);
      clearTimeout(timeoutRef.current);
    }
  };

  const loginWithCredentials = () => {
    if (!username || !password) {
      return;
    }

    props.onLoginRequest();
    setLoginState('starting');

    setCredentialLoginModalVisible(false);
    const credentials = { identifier: username, password: password, method: 'credentials' };

    api
      .logInTest(credentials)
      .then((result) => {
        checkMaintenanceMode(result);
        props.onLoginSuccess(result);
      })
      .catch((error) => {
        props.onLoginError(error);
      });
  };

  const onUsernameInput = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordInput = (e) => {
    setPassword(e.target.value);
  };

  const toggleCredentialLoginModal = () => setCredentialLoginModalVisible(!credentialLoginModalVisible);

  const cancelLogin = () => {
    setLoginState('idle');
    setActiveQrCode('');
    clearTimeout(timeoutRef.current);
  };

  const retry = () => {
    props.acknowledgeLoginError();
    setLoginState('idle');
  };

  const setLoginState = (newState) => {
    _setLoginState(newState);
    loginStateRef.current = newState;
  };

  const from = {
    pathname:
      props.location?.state?.from?.pathname && props.location.state.from.pathname !== `/${APPLICATION_BASE_PATH}`
        ? props.location.state.from.pathname
        : `/${APPLICATION_BASE_PATH}${props.authorities?.includes(AUTHORITY.HANDLE_NEXT_GEN_PATIENTS) ? '/inbox' : ''}`
  };

  if (props.redirectToReferrer && !props.loginError) {
    return <Redirect to={from} />;
  }

  return (
    <Fragment>
      <div className="login-container">
        <div className="login-form">
          <Logo className="clinic-logo" />
          {loginState === 'idle' ? (
            <Button onClick={beginLoginFlow} className="login-button">
              <Translate value="login_view.login_with" />
            </Button>
          ) : null}

          {loginState === 'starting' ? (
            <div className="flex justify-center mt-10">
              <Spinner />
            </div>
          ) : null}

          {loginState === 'polling' && activeQrCode ? (
            <>
              <div className="flex justify-center" dangerouslySetInnerHTML={{ __html: activeQrCode }} />
              <div className="text-left">
                <Translate value="login_view.instruction1" dangerousHTML />
              </div>
              <div className="mt-40">
                <Button variant="text" onClick={cancelLogin}>
                  <Translate value="login_view.cancel_login" />
                </Button>
              </div>
            </>
          ) : null}

          {loginState === 'user_signing' ? (
            <div>
              <p>
                <Translate value="login_view.instruction2" dangerousHTML />
              </p>
              <div className="flex justify-center">
                <Spinner />
              </div>
            </div>
          ) : null}

          {loginState === 'error' ? (
            <>
              <div className="error-message">
                {props.loginError && props.loginError.response ? (
                  <Translate
                    value={API_ERROR_CODES[JSON.parse(props.loginError.response.text).code] || 'server_error.fallback'}
                  />
                ) : typeof props.loginError === 'string' ? (
                  <span>{props.loginError}</span>
                ) : (
                  <Translate value="server_error.connectivity" />
                )}
              </div>
              <Button onClick={retry} variant="secondary" className="mt-20">
                <Translate value="global.buttons.retry" />
              </Button>
            </>
          ) : null}
        </div>

        <footer className="login-footer">
          <strong>Blodtrycksdoktorn AB</strong>
          <span className="flex mr-5 ml-5">•</span>
          <span>Bredbandet 1, 392 51 Kalmar • 0480-797 960 • support@blodtrycksdoktorn.se</span>
          <div className="build-number">
            <div>
              Version:{' '}
              <span
                className={allowCredentialsLogin ? 'pointer' : undefined}
                onClick={allowCredentialsLogin ? toggleCredentialLoginModal : undefined}
              >
                {metadata.version}
              </span>{' '}
              (Build: #{process.env.REACT_APP_BITRISE_BUILD_NUMBER || 0})
            </div>
          </div>
        </footer>
      </div>
      <Dialog
        isOpen={credentialLoginModalVisible}
        onClose={toggleCredentialLoginModal}
        onEnterPress={loginWithCredentials}
        size="small"
        actions={
          <Button onClick={loginWithCredentials} disabled={!username.length || !password.length}>
            <Translate value="login_view.login" />
          </Button>
        }
      >
        <div className="stack-1">
          <div>
            <input
              placeholder="E-postadress"
              type="text"
              className="w-100"
              value={username}
              onChange={onUsernameInput}
            />
          </div>
          <div>
            <input
              placeholder="Lösenord"
              type="password"
              className="w-100"
              value={password}
              onChange={onPasswordInput}
            />
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectToReferrer: state.auth.redirectToReferrer,
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.error,
    authorities: state.auth.authorities
  };
};

const mapActionsToProps = {
  onLoginRequest: loginRequest,
  onLoginSuccess: loginSuccess,
  onLoginError: loginError,
  acknowledgeLoginError,
  showMaintenanceModal
};

export default connect(mapStateToProps, mapActionsToProps)(LoginView);
